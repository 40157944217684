/* .styled-box {
    @apply border-solid border-2 border-b-[6px] ;
    transform: translateY(0px);
} */

.styled-box {
    @apply border-2 border-b-[6px] border-solid rounded-xl p-6 flex flex-col justify-between;
    transition: all 0.1s ease-in-out;
    transform: translateY(0px);
}
.styled-box:hover {
    @apply border-2 border-b-2 border-solid mt-[4px];
    /* background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)); */
    transform: translateY(0px);
}