.button {
    @apply font-button justify-center items-center inline-flex;
}

.button--xs {
    @apply px-2 py-1 rounded-xs;
}

.button--sm {
    @apply px-4 py-2 rounded-sm;
}

.button--md {
    @apply px-8 py-4 rounded-md;
}

.button--lg {
    @apply px-10 py-5 rounded-lg text-heading-xsmall;
}

.button--xl {
    @apply px-12 py-6 rounded-xl text-heading-small;
}

.button--primary {
    @apply bg-pf-primary text-white border-2 border-b-[6px] border-solid border-pf-primary-S2;
    transition: all 0.1s ease-in-out;
    transform: translateY(0px);
}

.button--primary:not(:disabled):hover {
    @apply bg-pf-primary-S1 text-white border-2 border-b-4 border-solid border-pf-primary-S2 mt-[2px];
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    transform: translateY(0px);
}

.button--primary:not(:disabled):active {
    @apply bg-pf-primary-S2 text-white border-2 border-solid border-pf-primary-S2 mt-[4px];
    transform: translateY(0px);
}

.button--primary:disabled {
    @apply bg-pf-gray-T2 text-white border-2 border-solid border-pf-gray-T1 mt-[4px];
    transform: translateY(4px);
}

.button--secondary {
    @apply bg-pf-white text-pf-primary border border-solid border-pf-primary;
}

.button--secondary:not(:disabled):hover {
    @apply bg-pf-primary-T3 text-pf-primary border border-solid border-pf-primary;
}

.button--secondary:disabled {
    @apply bg-pf-gray-T3 text-pf-gray-T1 border border-solid border-pf-gray-T1;
}

.button--tertiary {
    @apply text-pf-black underline border-none;
}

.button--tertiary:not(:disabled):hover {
    @apply text-pf-primary underline;
}

.button--tertiary:disabled {
    @apply text-pf-gray-T1;
}

/* Icon positioning */
.button--icon-left {
    @apply flex-row-reverse;
}

.button--icon-right {
    @apply flex-row;
}

.button__icon {
    @apply mr-2;
}

.button--icon-right .button__icon {
    @apply mr-0 ml-2;
}

/* Cursor styles */
.button:not(:disabled) {
    @apply cursor-pointer;
}

.button:disabled {
    @apply cursor-not-allowed;
}

.button--loading {
    @apply !cursor-wait;

    .button__text {
        @apply opacity-0;
    }
}