.testimonial-card {
    @apply rounded-xl p-6 border-2 border-b-[6px] flex flex-1 flex-col xl:flex-row h-full;
}

.testimonial-quote {
    @apply flex flex-1 text-lg mb-4 xl:ml-6 text-pf-black h-full;
}

.testimonial-author {
    @apply flex items-center flex-col flex-1 ;
}

.testimonial-avatar {
    @apply w-full rounded-full mb-4 bg-pf-white aspect-square;
}

.testimonial-name {
    @apply font-semibold text-pf-black;
}

.testimonial-role {
    @apply text-sm text-pf-gray;
}
