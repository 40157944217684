.dashboard-card {
    @apply bg-pf-white rounded-lg p-4;
}

.dashboard-card-row {
    @apply flex justify-between items-center;
}

.dashboard-card-name {
    @apply text-heading-xsmall line-clamp-1;
}

.dashboard-card-project {
    @apply text-body text-pf-gray line-clamp-1;
}
