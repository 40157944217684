@tailwind base; /* Preflight will be injected here */
@tailwind components;
@tailwind utilities;

:root {
    --background-color: "#FFFCFA";
    --panel-background-color: "#F5F5F5";
    font-size: 16px; /* Default font size for desktop */
}

/* Responsive font sizes */
@media screen and (max-width: 1024px) {
    :root {
        font-size: 14px; /* Font size for tablet */
    }
}

@media screen and (max-width: 640px) {
    :root {
        font-size: 12px; /* Font size for mobile */
    }
}

@media screen and (max-width: 379px) {
    :root {
        font-size: 10px; /* Font size for mobile */
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body,
html {
    /* font-family: 'DM Sans', sans-serif; */
    overflow-wrap: break-word;
    word-break: break-word;
}

section {
    background-color: var(--background-color);
    padding-top: 8rem;
}

::-webkit-scrollbar-track {
    background: #ff0;
}

ul li {
    @apply text-body;
}

ul li {
    @apply text-body;
}

ul li:hover {
    @apply text-pf-primary;
}

h1 {
    @apply text-display-large;
}

h2 {
    @apply text-display-medium;
}

h3 {
    @apply text-heading-small;
}

h4 {
    @apply text-heading-xsmall;
}

p {
    @apply text-body;
}