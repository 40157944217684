.switch-toggle-container {
  @apply inline-flex items-center cursor-pointer text-pf-black text-body font-button;
}

.switch-toggle {
  @apply relative inline-block;
}

.switch-toggle-input {
  @apply opacity-0 w-0 h-0;
}

.switch-toggle-slider {
  @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-pf-gray-T1 border-2 border-pf-gray-T1 transition-all duration-200 rounded-full;
}

.switch-toggle-slider:before {
  content: "";
  @apply absolute bg-pf-white transition-all duration-200 rounded-full;
}

.switch-toggle-input:checked + .switch-toggle-slider {
  @apply bg-pf-primary border-pf-primary;
}

.switch-toggle-input:checked + .switch-toggle-slider:before {
  @apply transform bg-pf-white;
}

.switch-toggle-label {
  @apply text-pf-black transition-colors duration-200;
}

/* Size-specific styles */
.switch-toggle-xs .switch-toggle {
  @apply w-[20px] h-3;
}

.switch-toggle-xs .switch-toggle-slider:before {
  @apply h-2 w-2 left-0 bottom-0;
}

.switch-toggle-xs .switch-toggle-input:checked + .switch-toggle-slider:before {
  @apply translate-x-[8px];
}

.switch-toggle-sm .switch-toggle {
  @apply w-[28px] h-4;
}

.switch-toggle-sm .switch-toggle-slider:before {
  @apply h-3 w-3 left-0 bottom-0;
}

.switch-toggle-sm .switch-toggle-input:checked + .switch-toggle-slider:before {
  @apply translate-x-[12px];
}

.switch-toggle-md .switch-toggle {
  @apply w-[36px] h-[20px];
}

.switch-toggle-md .switch-toggle-slider:before {
  @apply h-[16px] w-[16px] left-[0px] bottom-[0px];
}

.switch-toggle-md .switch-toggle-input:checked + .switch-toggle-slider:before {
  @apply translate-x-[16px];
}

.switch-toggle-lg .switch-toggle {
  @apply w-[52px] h-[28px];
}

.switch-toggle-lg .switch-toggle-slider:before {
  @apply h-[20px] w-[20px] left-[2px] bottom-[2px];
}

.switch-toggle-lg .switch-toggle-input:checked + .switch-toggle-slider:before {
  @apply translate-x-[24px];
}

/* Size-specific label styles */
.switch-toggle-xs .switch-toggle-label {
  @apply text-[8px];
}

.switch-toggle-sm .switch-toggle-label {
  @apply text-xs;
}

.switch-toggle-md .switch-toggle-label {
  @apply text-sm;
}

.switch-toggle-lg .switch-toggle-label {
  @apply text-body;
}

/* Hover state */
.switch-toggle-container:hover .switch-toggle-slider {
  @apply border-pf-primary-S1;
}

.switch-toggle-container:hover .switch-toggle-label {
  @apply text-pf-primary;
}

/* Disabled state */
.switch-toggle-input:disabled + .switch-toggle-slider {
  @apply bg-pf-gray-T2 border-pf-gray-T2 cursor-not-allowed;
}

.switch-toggle-input:disabled + .switch-toggle-slider:before {
  @apply bg-pf-gray-T1 bg-opacity-50;
}

.switch-toggle-input:disabled:checked + .switch-toggle-slider {
  @apply bg-pf-gray-T1 border-pf-gray-T2;
}

.switch-toggle-input:disabled:checked + .switch-toggle-slider:before {
  @apply bg-pf-gray-T2;
}

.switch-toggle-input:disabled ~ .switch-toggle-label {
  @apply text-pf-gray-T1 cursor-not-allowed;
}